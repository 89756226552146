import React from "react";

import {
  ButtonLink,
  Headline1Sans,
  Overline,
  advertedPoolSize,
} from "@userbrain/website-ui";

import Layout from "../layouts/layout";
import { WavySeparator } from "../components/WavySeparator";

import * as helperStyles from "./helpers.module.css";
import * as styles from "./styles/careers.module.css";

import imgPortrait2 from "../images/portrait-2.svg";
import { ListCentered } from "../components/ListCentered";
import { ThreeColumnsIcons } from "../components/ThreeColumnsIcons";
import imageChooseAnyLanguage from "../images/lineIcons/choose-any-language.svg";
import imageAutomateUserTesting from "../images/lineIcons/automate-user-testing.svg";
import imageConferencesAndNetworking from "../images/lineIcons/conferences-and-networking.svg";
import { TwoColumns } from "../components/TwoColumns";
import imageToolVideo from "../images/toolVideo.jpg";

export default function TesterPool() {
  const jobItems = [
    // {
    //   title: 'Web Developer (m/f/d)',
    //   text: 'Part time, Graz/Austria',
    //   linkHref: 'https://apply.workable.com/userbrain/j/3EE1D854F5/'
    // },
    // {
    //   title: 'Customer Success Manager (m/f/d)',
    //   text: 'Part time, Remote',
    //   linkHref: 'https://apply.workable.com/userbrain/j/5FB4738A0E/'
    // },
    // {
    //   title: 'User Experience Design Internship (m/f/d)',
    //   text: 'Part time, Graz/Austria',
    //   linkHref: 'https://apply.workable.com/userbrain/j/7C08039EF1/'
    // },
    // {
    //   title: 'Customer Support Specialist (m/f/d)',
    //   text: 'Part time, Remote',
    //   linkHref: 'https://apply.workable.com/userbrain/j/2C59D30D2E/'
    // },
    {
      title: "Userbrain Tester [English]",
      text: "Remote (worldwide)",
      linkTo: "/careers/userbrain-tester-english",
    },
    {
      title: "Userbrain Tester [Deutsch]",
      text: "Remote (weltweit)",
      linkTo: "/careers/userbrain-tester-deutsch",
    },
    // {
    //   title: 'Userbrain Tester [Español]',
    //   text: 'Remoto (en todo el mundo)',
    //   linkTo: '/careers/userbrain-tester-espanol',
    // },
  ];

  return (
    <Layout title={"Careers"}>
      <article>
        <header className={styles.header}>
          <img src={imgPortrait2} alt="Lineart portrait of a woman" />
          <Overline className={styles.overline}>Careers</Overline>
          <Headline1Sans className={styles.title}>
            Join{" "}
            <span className={helperStyles.h1Serif}>
              &amp; shape the future of UX.
            </span>
          </Headline1Sans>
        </header>
        <section className={styles.openPositions}>
          <Overline customTag={"h2"} className={styles.openPositions_title}>
            Our open positions
          </Overline>
          {jobItems.length > 0 ? (
            <ListCentered
              className={styles.openPositions_list}
              withLearnMoreButton={true}
              learnMoreButtonIsPrimary={true}
              items={jobItems}
            />
          ) : (
            <div className={styles.emptyJobPositions}>
              There are currently no open positions.
            </div>
          )}
          <p className={styles.spontaneousApplication}>
            Want to send us a spontaneous application? Drop us an email at{" "}
            <ButtonLink href={`mailto:jobs@userbrain.com`} customTag={"a"}>
              jobs@userbrain.com
            </ButtonLink>
          </p>
        </section>

        <WavySeparator />

        <div className={helperStyles.container}>
          <TwoColumns
            className={helperStyles.spacingLg}
            heading={"Userbrain in a nutshell."}
            content={
              <>
                <p>
                  We’re a team of UX experts and designers who have been
                  consulting big-brand clients for over a decade. Once we saw
                  the gap for a tool that can carry out fast, simple, and
                  affordable user tests with real people — we built it.
                </p>
                <p>
                  Now, UX teams at Audi, Spotify, Red Bull and Virgin are
                  tapping into our pool of {advertedPoolSize} quality assured
                  testers, to build products people love to use.
                </p>
              </>
            }
            linkTo={"/about"}
            linkText={"Read more"}
            imgSrc={imageToolVideo}
            imgAlt={
              "Macbook Pro with knowledge base article about user testing Axure prototypes with Userbrain opened"
            }
          />

          <ThreeColumnsIcons
            className={helperStyles.spacingLg}
            title={"What we offer:"}
            col1Image={imageChooseAnyLanguage}
            col1Alt={"Line drawing of a globe with a pin"}
            col1Heading={
              <>
                Remote
                <br />
                workplace
              </>
            }
            col1Text={
              "We don’t mind where you’re located as long as you have a stable internet connection, can work with PST hours, and get the job done."
            }
            col2Image={imageAutomateUserTesting}
            col2Alt={"Infinity symbol with arrow"}
            col2Heading={
              <>
                Continuous
                <br />
                learning
              </>
            }
            col2Text={
              "At Userbrain, you’ll constantly develop your skill set — you get plenty of responsibility from day one and a ton of resources to learn from."
            }
            col3Image={imageConferencesAndNetworking}
            col3Alt={"Speaker with a microphone"}
            col3Heading={
              <>
                Networking &<br />
                conferences
              </>
            }
            col3Text={
              "We’ll take you to the coolest tech conferences and networking events, meet industry experts and have a little fun on the side."
            }
          />
        </div>
      </article>
    </Layout>
  );
}
